export interface IJsonModel {
  _modelData: any
  _modelType: string
}
export type JsonModelInstanceType = new (args: any) => IJsonModel

/**
 * A base class for models that can be written to disk, and then subsequently recreated.
 * This exists because writing javascript classes to disk for later use results in plain
 * objects being given back, rather than class instances. (as in, model methods will be undefined).
 * This uses the raw json/args that the model subclass is instantiated with, and writes them to disk
 * in order to recreate the same exact object as before. This should be used together with classTransformer.ts.
 */
export default abstract class JsonModel implements IJsonModel {
  _modelData: any
  _modelType: string

  /**
   * Creates a new JsonModel using the type and json to write to disk.
   * @param type the subclass type as a string. (e.g. "Activity", or "Device")
   * @param data the json used to instantiate the subclass. Used to recreate model objects persisted to disk.
   */
  constructor(type: string, data: any) {
    this._modelType = type
    this._modelData = data
  }
}
