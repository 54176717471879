import JsonModel from './JsonModel'
import Patient, {PatientData} from './Patient'

export type UserData = {
  id?: string
  email: string
  password?: string
  token?: string
  admin: boolean
  patient?: PatientData
}

class User extends JsonModel {
  id?: string
  email: string
  password?: string
  token?: string
  admin: boolean
  patient?: Patient

  constructor(args: UserData) {
    super('User', args)
    this.id = args.id
    this.email = args.email
    this.password = args.password
    this.token = args.token
    this.admin = args.admin
    if (args.patient) {
      this.patient = new Patient(args.patient)
    }
  }

  asJson() {
    const {email, password, admin} = this
    return {email, password, admin}
  }
}

export default User
