import React, {useState} from 'react'
import Button from '@mui/material/Button'
import {Avatar, Box, Container, TextField, Typography} from '@mui/material'
import {DataGrid} from '@mui/x-data-grid'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'
import {RootState} from '../../redux/rootReducer'
import {createDeviceColumns} from './deviceColumns'
import {createPatientColumns} from './patientColumns'
import {createUserColumns} from './userColumns'
import {actions as searchActions} from '../../redux/modules/search'
import {actions as userActions} from '../../redux/modules/users'
import {actions as deviceActions} from '../../redux/modules/devices'
import {actions as patientActions} from '../../redux/modules/patients'
import Device from 'models/Device'
import Patient from 'models/Patient'
import styles from './styles'

type Props = {
  onClickAddDevice: (patientId: string) => void
  onClickEditDevice: (device: Device) => void
  onClickEditPatient: (patient: Patient) => void
}

const SearchPage: React.FC<Props> = props => {
  const dispatch = useDispatch()
  const {results} = useSelector(({search}: RootState) => search)
  const users = _.values(results.users)
  const patients = _.values(results.patients)
  const devices = _.values(results.devices)
  const [query, setQuery] = useState('')
  const {onClickAddDevice, onClickEditDevice, onClickEditPatient} = props

  const onClickDeleteDevice = (id: string) => {
    dispatch(deviceActions.delete({id}))
  }
  const onClickDeletePatient = (id: string) => {
    dispatch(patientActions.delete({id}))
  }
  const onClickResetPassword = (id: string) => {
    dispatch(userActions.sendResetPasswordInstructions({id}))
  }

  const deviceColumns = createDeviceColumns(onClickEditDevice, onClickDeleteDevice)
  const patientColumns = createPatientColumns(
    onClickEditPatient,
    onClickAddDevice,
    onClickDeletePatient,
  )
  const userColumns = createUserColumns(onClickResetPassword)

  const search = () => {
    dispatch(searchActions.search({query: _.trim(query)}))
  }
  return (
    <Container component="main" sx={styles.root}>
      <Box textAlign="center">
        <Avatar sx={styles.logo} variant="square" src="logo.png" />
        <TextField
          sx={styles.searchBar}
          margin="normal"
          required
          id="query"
          placeholder="Enter device, patient or user data (case sensitive)"
          name="query"
          autoComplete="email"
          autoFocus
          onChange={e => setQuery(e.target.value)}
          onKeyDown={args => args.key === 'Enter' && search()}
        />
        <Button variant="contained" sx={styles.searchButton} onClick={search}>
          Search
        </Button>
      </Box>
      {!_.isEmpty(devices) && (
        <>
          <Typography variant="h5" sx={styles.devicesLabel}>
            Devices
          </Typography>
          <DataGrid
            rows={devices}
            columns={deviceColumns}
            hideFooter
            autoHeight
            experimentalFeatures={{newEditingApi: true}}
          />
        </>
      )}
      {!_.isEmpty(patients) && (
        <>
          <Typography variant="h5" sx={styles.patientsLabel}>
            Patients
          </Typography>
          <DataGrid
            rows={patients}
            columns={patientColumns}
            hideFooter
            autoHeight
            experimentalFeatures={{newEditingApi: true}}
          />
        </>
      )}
      {!_.isEmpty(users) && (
        <>
          <Typography variant="h5" sx={styles.usersLabel}>
            Users
          </Typography>
          <DataGrid
            rows={users}
            columns={userColumns}
            hideFooter
            autoHeight
            experimentalFeatures={{newEditingApi: true}}
          />
        </>
      )}
    </Container>
  )
}
export default SearchPage
