import {createStore, applyMiddleware, Store, AnyAction, Middleware, Dispatch} from 'redux'
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'
import {createLogger} from 'redux-logger'
import {persistStore, persistReducer} from 'redux-persist'
import environments from '../environments'
import {actions as appActions} from './modules/app'
import {actions as authActions} from './modules/auth'
import Api, {UnauthorizedError, ForbiddenError} from '../Api'
import rootSaga from './rootSaga'
import createRootReducer from './rootReducer'
import classTransformer from './classTransformer'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import {routerMiddleware} from 'connected-react-router'
import {History} from 'history'

const configureStore = function(history: History<any>) {
  const targetEnvironment =
    environments.find(
      environment => environment.name.toLowerCase() === process.env.NODE_ENV?.toLowerCase(),
    ) || environments[0]
  const api = new Api(targetEnvironment)
  const sagas = createSagaMiddleware({
    context: {api},
    onError: (error: Error) => {
      console.warn('Error', error)
      if (error === UnauthorizedError || error === ForbiddenError) {
        store.dispatch(authActions.logout())
      }
      sagas.run(rootSaga)
      store.dispatch(appActions.init())
      store.dispatch(appActions.showError({error}))
    },
  })

  const logger: Middleware<{}, any, Dispatch<AnyAction>> = createLogger({collapsed: true, diff: true}) as Middleware<{}, any, Dispatch<AnyAction>>;

  if (history.location.pathname.includes('resetPassword')) {
    history.block() // Prevent from redirecting to /login if trying to load a resetPassword page
  }
  const router = routerMiddleware(history)
  const middlewares: Middleware[] = [sagas, logger, router]

  const config = {
    transforms: [classTransformer],
    key: 'root',
    storage,
    debug: true,
  }
  const persistedReducer = persistReducer(config, createRootReducer(history))
  const store: Store<any, AnyAction> = createStore(
    persistedReducer,
    applyMiddleware(...middlewares),
  )
  /* eslint-disable-next-line */
  const persistor = persistStore(store, null, () => {
    sagas.run(rootSaga)
    console.info('Store init', store.getState())
    store.dispatch(appActions.init())
  })

  return {store, persistor}
}
export default configureStore