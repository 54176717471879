import {createAction, createReducer} from '@reduxjs/toolkit'
import {takeEvery, takeLatest, getContext, put, call, delay, select} from 'redux-saga/effects'
import User from '../../models/User'
import {actions as patientActions} from './patients'
import {actions as deviceActions} from './devices'
import {push} from 'connected-react-router'
import {RootState} from 'redux/rootReducer'
import Api from 'Api'

export type AuthState = {
  user?: User
  loading: boolean
  error?: String
}
export const initialState: AuthState = {
  loading: false,
}

export const actions = {
  login: createAction<{email: string; password: string}>('auth/login'),
  logout: createAction<void>('auth/logout'),
  setUser: createAction<{user: User}>('auth/setUser'),
  userInactive: createAction<void>('app/userInactive'),
  setError: createAction<{error?: string}>('auth/setError'),
}

export const reducer = createReducer(initialState, {
  [actions.logout.type]: (state, _) => {
    return {...state, user: undefined}
  },
  [actions.setUser.type]: (state, action: ReturnType<typeof actions.setUser>) => {
    const {user} = action.payload
    return {...state, user}
  },
  [actions.setError.type]: (state, action: ReturnType<typeof actions.setError>) => {
    const {error} = action.payload
    return {...state, error}
  },
})

const loginSaga = function*(action: ReturnType<typeof actions.login>) {
  const {email, password} = action.payload
  const api: Api = yield getContext('api')
  const user = yield call(api.login, email, password)
  if (user.status === 'error') {
    yield put(actions.setError({error: ''}))
    yield put(actions.setError({error: user.message}))
    yield put(push('/login'))
  } else {
    yield put(actions.setUser({user}))
    yield put(actions.setError({error: ''}))
    yield call(api.setToken, user.token)
    yield put(push('/'))
    yield put(patientActions.get())
    yield put(deviceActions.get())
  }
}
const logoutSaga = function*() {
  const api: Api = yield getContext('api')
  yield call(api.setToken, undefined)
  yield put(push('/login'))
}

// Every action will trigger the inactiveSaga (cause () => true). The inactiveSaga will
// wait X ms before dispatching the "inactive action". If there is a new action within
// this waiting time the previous execution of the inactiveSaga will be canceled (because
// takeLatest, see [redux-saga effect docs](https://redux-saga.js.org/docs/api/#takelatestpattern-saga-args)
// for takeLatest) and started from the beginning again.
const inactiveSaga = function*() {
  const user: User = yield select(({auth}: RootState) => auth.user)

  if (user) {
    yield delay(1200000)
    yield put(actions.logout())
  }
}

export const saga = function*() {
  yield takeEvery(actions.login, loginSaga)
  yield takeEvery(actions.logout, logoutSaga)
  yield takeLatest('*', inactiveSaga)
}
