import {DateTimeFormatter, ZonedDateTime, LocalDateTime} from '@js-joda/core'
import {Locale} from '@js-joda/locale_en-us'

declare module '@js-joda/core' {
  interface ZonedDateTime {
    /**
     * Converts a ZonedDateTime to a formatted string
     * @returns a formatted string
     */
    formatted(): string
  }
  interface LocalDateTime {
    /**
     * Converts a LocalDateTime to a formatted string
     * @returns a formatted string
     */
    formatted(): string
  }
}

ZonedDateTime.prototype.formatted = function() {
  return this.format(DateTimeFormatter.ofPattern('M/d/yyyy h:mm a Z').withLocale(Locale.US))
}
LocalDateTime.prototype.formatted = function() {
  return this.format(DateTimeFormatter.ofPattern('M/d/yyyy h:mm a').withLocale(Locale.US))
}
