import {createAction, createReducer} from '@reduxjs/toolkit'
import {takeEvery, getContext, put, call} from 'redux-saga/effects'
import {Dictionary, keyBy} from 'lodash'
import Device from '../../models/Device'
import Api, {UnauthorizedError} from 'Api'
import {actions as searchActions} from './search'

export type DevicesState = {
  devices: Dictionary<Device>
  loading: boolean
  error: string | undefined
}
export const initialState: DevicesState = {
  devices: {},
  loading: false,
  error: undefined,
}

export const actions = {
  set: createAction<{devices: Device[]}>('devices/set'),
  get: createAction<void>('devices/get'),
  update: createAction<{device: Device}>('devices/update'),
  add: createAction<{device: Device; patientId: string}>('devices/add'),
  delete: createAction<{id: string}>('devices/delete'),
  setLoading: createAction<{loading: boolean}>('devices/setLoading'),
  setError: createAction<{error?: string}>('devices/setError'),
}

export const reducer = createReducer(initialState, {
  [actions.set.type]: (state, action: ReturnType<typeof actions.set>) => {
    const {devices} = action.payload
    return {...state, devices: keyBy(devices, 'id')}
  },
  [actions.setLoading.type]: (state, action: ReturnType<typeof actions.setLoading>) => {
    const {loading} = action.payload
    return {...state, loading}
  },
  [actions.setError.type]: (state, action: ReturnType<typeof actions.setError>) => {
    const {error} = action.payload
    return {...state, error}
  },
})

const getSaga = function*() {
  const api: Api = yield getContext('api')
  const devices: Device[] = yield call(api.getDevices)
  yield put(actions.set({devices}))
}

const addSaga = function*(action: ReturnType<typeof actions.add>) {
  const {device, patientId} = action.payload
  const api: Api = yield getContext('api')
  yield put(actions.setLoading({loading: true}))
  yield put(actions.setError({error: undefined}))
  try {
    yield call(api.addDevice, device, patientId)
    yield put(searchActions.refreshSearch())
  } catch (e) {
    const error = e as any
    if (error === UnauthorizedError) {
      // throw so it redirects the user to the login page
      throw error
    }
    yield put(actions.setError({error: error.message}))
  }
  yield put(actions.setLoading({loading: false}))
}

const deleteSaga = function*(action: ReturnType<typeof actions.delete>) {
  const {id} = action.payload
  const api: Api = yield getContext('api')
  yield call(api.deleteDevice, id)
  yield put(searchActions.refreshSearch())
}

const updateSaga = function*(action: ReturnType<typeof actions.update>) {
  const {device} = action.payload
  const api: Api = yield getContext('api')
  yield put(actions.setLoading({loading: true}))
  yield put(actions.setError({error: undefined}))

  try {
    yield call(api.updateDevice, device)
    yield put(searchActions.refreshSearch())
  } catch (e) {
    const error = e as any
    if (error === UnauthorizedError) {
      // throw so it redirects the user to the login page
      throw error
    }
    yield put(actions.setError({error: error.message}))
  }
  yield put(actions.setLoading({loading: false}))
}

export const saga = function*() {
  yield takeEvery(actions.delete, deleteSaga)
  yield takeEvery(actions.get, getSaga)
  yield takeEvery(actions.add, addSaga)
  yield takeEvery(actions.update, updateSaga)
}
