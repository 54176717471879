import React, {useState} from 'react'
import {Button, CircularProgress, Container, TextField, Typography} from '@mui/material'
import {Box} from '@mui/system'
import {ModalPage} from 'nav/ModalPage'
import {useDispatch, useSelector} from 'react-redux'
import {actions as userActions} from '../../redux/modules/users'
import User from 'models/User'
import {validate} from 'isemail'
import _ from 'lodash'
import {RootState} from '../../redux/rootReducer'
import styles from './styles'

const CreateUserPage: ModalPage = props => {
  const dispatch = useDispatch()
  const loading = useSelector(({users}: RootState) => users.loading)
  const userError = useSelector(({users}: RootState) => users.error)
  const {onClickCancel} = props
  const [shouldClose, setShouldClose] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [formError, setFormError] = useState<string | undefined>()
  const [emailError, setEmailError] = useState<string | undefined>()
  const [passwordError, setPasswordError] = useState<string | undefined>()

  if (loading && !shouldClose) {
    setShouldClose(true)
  }
  if (shouldClose && !userError && !loading) {
    onClickCancel()
  }

  const closePage = () => {
    dispatch(userActions.setError({error: undefined}))
    onClickCancel()
  }

  const saveUser = () => {
    setFormError(undefined)
    setEmailError(undefined)
    setPasswordError(undefined)
    if (_.isEmpty(email) || _.isEmpty(password)) {
      setFormError('All fields are required.')
      return
    }
    const validEmail = validate(email, {minDomainAtoms: 2})
    if (!validEmail) {
      setEmailError('Valid email address required.')
      return
    }
    if (password.length < 10) {
      setPasswordError('Password must be longer than 10 characters.')
      return
    }
    const user = new User({email, password, admin: true})
    dispatch(userActions.create({user}))
  }

  return (
    <Container>
      <Box sx={styles.modal}>
        <Typography sx={styles.headerLabel} variant="h4">
          Add user
        </Typography>
        <Typography sx={styles.formError} variant="body1" color="red">
          {formError ?? userError}
        </Typography>
        <TextField
          error={!!emailError}
          helperText={emailError}
          sx={styles.textField}
          fullWidth
          onChange={e => setEmail(e.currentTarget.value)}
          placeholder="Email address"
        />
        <TextField
          error={!!passwordError}
          helperText={passwordError}
          sx={styles.textField}
          fullWidth
          onChange={e => setPassword(e.currentTarget.value)}
          placeholder="Password"
        />
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.cancelButton} color="warning" onClick={closePage}>
            Cancel
          </Button>
          {loading && (
            <Box sx={styles.progress}>
              <CircularProgress size={20} />
            </Box>
          )}
          {!loading && (
            <Button onClick={saveUser} variant="contained">
              Save
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default CreateUserPage
