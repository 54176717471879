import Rollbar from 'rollbar'

export default () => {
  if (
    process.env.NODE_ENV &&
    ['staging', 'production'].includes(process.env.NODE_ENV.toLowerCase())
  ) {
    // eslint-disable-next-line no-new
    new Rollbar({
      accessToken: '79f229814f824cc78e097eb32b04b9ac',
      captureUncaught: true,
      captureUnhandledRejections: true,
    })
  }
}
