import React, {useEffect, useState} from 'react'
import {actions as authActions} from '../../redux/modules/auth'
import {useDispatch, useSelector} from 'react-redux'
import {Avatar, Box, Button, Container, TextField, Typography} from '@mui/material'
import styles from './styles'
import {RootState} from 'redux/rootReducer'

const LoginScreen: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const errorMessage = useSelector(({auth}: RootState) => auth.error)
  useEffect(checkErrorMessage, [errorMessage])

  function checkErrorMessage() {
    if (errorMessage && errorMessage !== '' && errorMessage !== undefined) {
      // eslint-disable-next-line no-alert
      alert(errorMessage)
    }
  }

  const login = () => {
    dispatch(authActions.login({email, password}))
  }

  return (
    <Container component="main" sx={styles.root}>
      <Avatar sx={styles.logo} variant="square" src="logo.png" />
      <Box sx={styles.form} textAlign="center">
        <Typography sx={styles.formError} variant="body1" color="red">
          {errorMessage && errorMessage}
        </Typography>
        <TextField
          sx={styles.textField}
          onChange={e => setEmail(e.target.value)}
          placeholder="Email"
          onKeyDown={args => args.key === 'Enter' && login()}
        />
        <TextField
          sx={styles.textField}
          type="password"
          onChange={e => setPassword(e.target.value)}
          placeholder="Password"
          onKeyDown={args => args.key === 'Enter' && login()}
        />
        <Button variant="contained" sx={styles.loginButton} onClick={login}>
          Login
        </Button>
      </Box>
    </Container>
  )
}
export default LoginScreen
