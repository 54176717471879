export default {
  root: {
    border: 0,
    marginTop: 10,
    marginBottom: 10,
  },
  logo: {
    width: 300,
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  searchBar: {
    marginTop: 5,
    width: '80%',
  },
  searchButton: {
    float: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 300,
    marginTop: 2,
  },
  devicesLabel: {
    marginBottom: 3,
    marginTop: 5,
  },
  patientsLabel: {
    marginBottom: 3,
    marginTop: 5,
  },
  usersLabel: {
    marginBottom: 3,
    marginTop: 5,
  },
}
