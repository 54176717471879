import {combineReducers} from 'redux'
import {reducer as app} from './modules/app'
import {reducer as environments} from './modules/environments'
import {reducer as devices} from './modules/devices'
import {reducer as exportData} from './modules/exportData'
import {reducer as usage} from './modules/usage'
import {reducer as activity} from './modules/activity'
import {reducer as pain} from './modules/pain'
import {reducer as users} from './modules/users'
import {reducer as auth} from './modules/auth'
import {reducer as patients} from './modules/patients'
import {reducer as search} from './modules/search'
import {connectRouter} from 'connected-react-router'
import {History} from 'history'

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>
const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app,
    environments,
    devices,
    usage,
    activity,
    users,
    auth,
    pain,
    patients,
    exportData,
    search,
  })

export default createRootReducer
