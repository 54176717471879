const styles = {
  root: {
    border: 0,
    marginTop: 10,
  },
  logo: {
    width: 300,
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  form: {
    paddingTop: 5,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    marginBottom: 1,
    width: 300,
  },
  loginButton: {
    width: 200,
    margin: 2,
  },
  formError: {
    marginBottom: 3,
  },
}
export default styles
