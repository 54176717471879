import React from 'react'
import {Provider} from 'react-redux'
import configureStore from './redux/configureStore'
import Navigation from './nav'
import {createBrowserHistory} from 'history'
import {PersistGate} from 'redux-persist/lib/integration/react'
import {ConnectedRouter} from 'connected-react-router'

const history = createBrowserHistory()
const {store, persistor} = configureStore(history)

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <Navigation />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    )
  }
}
