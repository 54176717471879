import JsonModel from './JsonModel'
import {ZonedDateTime, ZoneId} from '@js-joda/core'
import Device, {DeviceData} from './Device'

type ActivityData = {
  id?: string
  date: string
  samplesTaken: number
  histogram: number[]
  device?: DeviceData
  hourIndex: number
}

class Activity extends JsonModel {
  id?: string
  date: ZonedDateTime
  samplesTaken: number
  histogram: number[]
  device?: Device
  hourIndex: number

  constructor(args: ActivityData) {
    super('Activity', args)
    this.id = args.id
    this.date = ZonedDateTime.parse(args.date)
    this.samplesTaken = args.samplesTaken
    this.histogram = args.histogram
    this.hourIndex = args.hourIndex
    if (args.device) {
      this.device = new Device(args.device)
    }
  }

  /**
   * The datetime object for the local timezone
   */
  get localDate() {
    return this.date.withZoneSameInstant(ZoneId.SYSTEM).toLocalDateTime()
  }

  /**
   * Returns the data inluded when exporting a csv
   */
  get exportData() {
    const {id, hourIndex, date, samplesTaken, histogram} = this._modelData
    return {
      id,
      hourIndex,
      date,
      samplesTaken,
      histogram: `[${histogram.join(',')}]`,
      deviceId: this.device?.id,
      deviceName: this.device?.name,
      deviceUdid: this.device?.udid,
      patientId: this.device?.patient?.id,
    }
  }
}

export default Activity
