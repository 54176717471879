const styles = {
  modal: {
    borderRadius: 3,
    backgroundColor: 'white',
    padding: 5,
  },
  headerLabel: {
    marginBottom: 3,
  },
  formError: {
    marginBottom: 3,
  },
  textField: {
    marginBottom: 2,
  },
  buttonContainer: {
    textAlign: 'right',
    paddingTop: 3,
  },
  cancelButton: {
    marginRight: 3,
  },
  progress: {
    paddingTop: 1,
    float: 'right',
  },
}
export default styles
