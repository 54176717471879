import React from 'react'
import {GridColDef} from '@mui/x-data-grid'
import {Button} from '@mui/material'
import Patient from 'models/Patient'

export const createPatientColumns = (
  onClickEdit: (patient: Patient) => void,
  onClickAddDevice: (patientId: string) => void,
  onClickDelete: (patientId: string) => void,
) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
    },
    {
      field: 'firstName',
      headerName: 'First name',
      flex: 1,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      flex: 1,
    },
    {
      field: 'physicianName',
      headerName: 'Physician name',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: '',
      hideable: false,
      disableColumnMenu: true,
      type: 'actions',
      width: 270,
      renderCell: params => (
        <>
          <Button
            sx={{marginRight: 1}}
            variant="contained"
            onClick={() => onClickAddDevice(params.id.toString())}>
            Add device
          </Button>
          <Button variant="contained" onClick={() => onClickEdit(params.row)}>
            Edit
          </Button>
          <Button
            sx={{marginLeft: 2}}
            onClick={() => onClickDelete(params.id.toString())}
            variant="contained"
            color="error">
            Delete
          </Button>
        </>
      ),
    },
  ]
  return columns
}
