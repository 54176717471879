import {createAction, createReducer} from '@reduxjs/toolkit'
import {takeEvery, getContext, put, call} from 'redux-saga/effects'
import _ from 'lodash'
import {parse} from 'json2csv'
import startDownload from '../../startDownload'
import Usage from 'models/Usage'
import Activity from 'models/Activity'
import Pain from 'models/Pain'
import {LocalDateTime} from '@js-joda/core'
import Api from 'Api'

export type ExportDataState = {
  exporting: boolean
}
export const initialState: ExportDataState = {
  exporting: false,
}

export const actions = {
  exportAll: createAction<void>('exportData/exportAll'),
  setExporting: createAction<{exporting: boolean}>('exportData/setExporting'),
}

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.setExporting, (state, action: ReturnType<typeof actions.setExporting>) => {
      const {exporting} = action.payload;
      state.exporting = exporting;
    });
});

const exportAllSaga = function*() {
  yield put(actions.setExporting({exporting: true}))
  const dateString = LocalDateTime.now().toString()
  const api: Api = yield getContext('api')
  const activity: Activity[] = yield call(api.getAllActivity)
  const usage: Usage[] = yield call(api.getAllUsage)
  const pain: Pain[] = yield call(api.getAllPain)

  const activityData = _.map(activity, 'exportData')
  const usageData = _.map(usage, 'exportData')
  const painData = _.map(pain, 'exportData')

  const activityCsv = parse(activityData)
  yield call(startDownload, activityCsv, `activity-${dateString}.csv`)

  const painCsv = parse(painData)
  yield call(startDownload, painCsv, `pain-${dateString}.csv`)

  const usageCsv = parse(usageData)
  yield call(startDownload, usageCsv, `usage-${dateString}.csv`)
  yield put(actions.setExporting({exporting: false}))
}

export const saga = function*() {
  yield takeEvery(actions.exportAll, exportAllSaga)
}
