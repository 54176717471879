import JsonModel from './JsonModel'
import {ZonedDateTime, ZoneId} from '@js-joda/core'
import Device, {DeviceData} from './Device'

type UsageData = {
  id?: string
  date: string
  batteryIn: number
  treatmentOn: number
  batteryMin: number
  batteryMax: number
  device?: DeviceData
  hourIndex: number
}

class Usage extends JsonModel {
  id?: string
  date: ZonedDateTime
  batteryIn: number
  treatmentOn: number
  batteryMin: number
  batteryMax: number
  device?: Device
  hourIndex: number

  constructor(args: UsageData) {
    super('Usage', args)
    this.id = args.id
    this.date = ZonedDateTime.parse(args.date)
    this.batteryIn = args.batteryIn
    this.treatmentOn = args.treatmentOn
    this.batteryMin = args.batteryMin
    this.batteryMax = args.batteryMax
    this.hourIndex = args.hourIndex
    if (args.device) {
      this.device = new Device(args.device)
    }
  }

  /**
   * The datetime object for the local timezone
   */
  get localDate() {
    return this.date.withZoneSameInstant(ZoneId.SYSTEM).toLocalDateTime()
  }

  /**
   * Returns the data inluded when exporting a csv
   */
  get exportData() {
    const {id, date, batteryIn, treatmentOn, batteryMin, batteryMax, hourIndex} = this._modelData
    return {
      id,
      date,
      batteryIn,
      treatmentOn,
      batteryMin,
      batteryMax,
      hourIndex,
      deviceId: this.device?.id,
      deviceName: this.device?.name,
      deviceUdid: this.device?.udid,
      patientId: this.device?.patient?.id,
    }
  }
}

export default Usage
