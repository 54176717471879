import {createAction, createReducer} from '@reduxjs/toolkit'
import {takeEvery, getContext, put, call} from 'redux-saga/effects'
import {Dictionary, keyBy} from 'lodash'
import User from '../../models/User'
import Api from 'Api'
import {actions as searchActions} from './search'

export type UsersState = {
  users: Dictionary<User>
  loading: boolean
  error: string | undefined
}
export const initialState: UsersState = {
  users: {},
  loading: false,
  error: undefined,
}

export const actions = {
  set: createAction<{users: User[]}>('users/set'),
  get: createAction<void>('users/get'),
  create: createAction<{user: User}>('users/create'),
  sendResetPasswordInstructions: createAction<{id: string}>('users/sendResetPasswordInstructions'),
  resetPassword: createAction<{
    userId: string
    password: string
    resetPasswordToken: string
  }>('users/resetPassword'),
  setLoading: createAction<{loading: boolean}>('auth/setLoading'),
  setError: createAction<{error?: string}>('auth/setError'),
}

export const reducer = createReducer(initialState, {
  [actions.set.type]: (state, action: ReturnType<typeof actions.set>) => {
    const {users} = action.payload
    return {...state, users: keyBy(users, 'id')}
  },
  [actions.setLoading.type]: (state, action: ReturnType<typeof actions.setLoading>) => {
    const {loading} = action.payload
    return {...state, loading}
  },
  [actions.setError.type]: (state, action: ReturnType<typeof actions.setError>) => {
    const {error} = action.payload
    return {...state, error}
  },
})

const createSaga = function*(action: ReturnType<typeof actions.create>) {
  const {user} = action.payload
  const api: Api = yield getContext('api')
  yield put(actions.setLoading({loading: true}))
  yield put(actions.setError({error: undefined}))
  try {
    yield call(api.createUser, user)
    yield put(searchActions.refreshSearch())
  } catch (e) {
    const error = e as any
    yield put(actions.setError({error: error.message}))
  }
  yield put(actions.setLoading({loading: false}))
}

const getSaga = function*() {
  const api: Api = yield getContext('api')
  const users: User[] = yield call(api.getUsers)
  yield put(actions.set({users}))
}

const sendResetPasswordInstructionsSaga = function*(
  action: ReturnType<typeof actions.sendResetPasswordInstructions>,
) {
  const {id} = action.payload
  const api: Api = yield getContext('api')
  const response = yield call(api.sendResetPasswordInstructions, id)
  if (response.message) {
    alert(response.message)
  }
}

const resetPasswordSaga = function*(action: ReturnType<typeof actions.resetPassword>) {
  const {password, userId, resetPasswordToken} = action.payload
  const api: Api = yield getContext('api')
  const response = yield call(api.resetUserPassword, userId, password, resetPasswordToken)
  yield put(actions.setError({error: undefined}))
  if (response.message) {
    alert(response.message)
    yield put(
      actions.setError({
        error:
          response.message === 'Password must be longer than or equal to 10 characters' ||
          response.message === 'Your password reset link expired, please request a new one.'
            ? response.message
            : undefined,
      }),
    )
  }
}

export const saga = function*() {
  yield takeEvery(actions.get, getSaga)
  yield takeEvery(actions.create, createSaga)
  yield takeEvery(actions.sendResetPasswordInstructions, sendResetPasswordInstructionsSaga)
  yield takeEvery(actions.resetPassword, resetPasswordSaga)
}
