import {createAction, createReducer} from '@reduxjs/toolkit'
import {takeEvery, getContext, put, call} from 'redux-saga/effects'
import {keyBy} from 'lodash'
import Pain from '../../models/Pain'
import Api from 'Api'

export type painState = {
  [key: string]: Pain
}
export const initialState: painState = {}

export const actions = {
  set: createAction<{pain: Pain[]}>('pain/set'),
  get: createAction<{patientId: string}>('pain/get'),
}

export const reducer = createReducer(initialState, {
  [actions.set.type]: (state, action: ReturnType<typeof actions.set>) => {
    const {pain} = action.payload
    return keyBy(pain, 'id')
  },
})

const getSaga = function*(action: ReturnType<typeof actions.get>) {
  const {patientId} = action.payload
  const api: Api = yield getContext('api')
  const pain: Pain[] = yield call(api.getPain, patientId)
  yield put(actions.set({pain}))
}

export const saga = function*() {
  yield takeEvery(actions.get, getSaga)
}
