import {createAction, createReducer} from '@reduxjs/toolkit'
import {takeEvery, getContext, call, put, delay, select} from 'redux-saga/effects'
import {RootState} from 'redux/rootReducer'
import {push} from 'connected-react-router'
import {actions as patientActions} from './patients'
import {actions as exportDataActions} from './exportData'
import {actions as userActions} from './users'
import {actions as deviceActions} from './devices'
import {actions as searchActions} from './search'
import User from '../../models/User'
import Api from 'Api'

export type AppState = {
  error?: Error
}
export const initialState: AppState = {}

export const actions = {
  init: createAction<void>('app/init'),
  setError: createAction<{error?: Error}>('app/setError'),
  showError: createAction<{error: Error}>('app/showError'),
}

export const reducer = createReducer(initialState, {
  [actions.setError.type]: (state, action: ReturnType<typeof actions.setError>) => {
    const {error} = action.payload
    return {...state, error}
  },
})

const initSaga = function*() {
  const user: User = yield select(({auth}: RootState) => auth.user)
  yield put(exportDataActions.setExporting({exporting: false})) // clears an unfinished export
  yield put(actions.setError({error: undefined}))
  yield put(userActions.setError({error: undefined}))
  yield put(patientActions.setError({error: undefined}))
  yield put(deviceActions.setError({error: undefined}))
  yield put(userActions.setLoading({loading: false}))
  yield put(patientActions.setLoading({loading: false}))
  yield put(deviceActions.setLoading({loading: false}))
  yield put(searchActions.setLoading({loading: false}))
  yield put(searchActions.setResults({results: {users: [], devices: [], patients: []}}))
  if (user) {
    const api: Api = yield getContext('api')
    yield call(api.setToken, user.token)
    yield put(patientActions.get())
  } else {
    yield put(push('/login'))
  }
}

const showErrorSaga = function*(action: ReturnType<typeof actions.showError>) {
  const {error} = action.payload
  yield put(actions.setError({error}))
  yield delay(5000)
  yield put(actions.setError({error: undefined}))
}

export const saga = function*() {
  yield takeEvery(actions.init, initSaga)
  yield takeEvery(actions.showError, showErrorSaga)
}
