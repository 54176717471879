import React, {useState} from 'react'
import {Button, CircularProgress, Container, TextField, Typography} from '@mui/material'
import {Box} from '@mui/system'
import {ModalPage} from 'nav/ModalPage'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {RootState} from '../../redux/rootReducer'
import {actions as deviceActions} from '../../redux/modules/devices'
import Device from 'models/Device'
import styles from './styles'

const CreateDevicePage: ModalPage<{patientId?: string; device?: Device}> = props => {
  const dispatch = useDispatch()
  const loading = useSelector(({devices}: RootState) => devices.loading)
  const deviceError = useSelector(({devices}: RootState) => devices.error)
  const {onClickCancel, patientId, device} = props
  const [shouldClose, setShouldClose] = useState(false)
  const [name, setName] = useState(device?.name ?? '')
  const [udid, setUdid] = useState(device?.udid ?? '')
  const [formError, setFormError] = useState<string | undefined>()

  if (loading && !shouldClose) {
    setShouldClose(true)
  }
  if (shouldClose && !deviceError && !loading) {
    onClickCancel()
  }

  const closePage = () => {
    dispatch(deviceActions.setError({error: undefined}))
    onClickCancel()
  }

  const saveDevice = () => {
    setFormError(undefined)
    if (_.isEmpty(name) || _.isEmpty(udid)) {
      setFormError('All fields are required.')
      return
    }
    if (patientId) {
      const newDevice = new Device({name, udid, createdAt: new Date()})
      dispatch(deviceActions.add({device: newDevice, patientId}))
    } else if (device) {
      device.name = name
      device.udid = udid
      dispatch(deviceActions.update({device}))
    }
  }

  return (
    <Container>
      <Box sx={styles.modal}>
        <Typography sx={styles.headerLabel} variant="h4">
          Add device
        </Typography>
        <Typography sx={styles.formError} variant="body1" color="red">
          {formError ?? deviceError}
        </Typography>

        <TextField
          sx={styles.textField}
          fullWidth
          defaultValue={name}
          onChange={e => setName(e.currentTarget.value)}
          placeholder="Device name"
        />
        <TextField
          sx={styles.textField}
          fullWidth
          defaultValue={udid}
          onChange={e => setUdid(e.currentTarget.value)}
          placeholder="UDID"
        />
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.cancelButton} color="warning" onClick={closePage}>
            Cancel
          </Button>
          {loading && (
            <Box sx={styles.progress}>
              <CircularProgress size={20} />
            </Box>
          )}
          {!loading && (
            <Button onClick={saveDevice} variant="contained">
              Save
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default CreateDevicePage
