import {createTransform} from 'redux-persist'
import traverse from 'traverse'
import Activity from 'models/Activity'
import Device from 'models/Device'
import Usage from 'models/Usage'
import User from 'models/User'
import Patient from 'models/Patient'
import {JsonModelInstanceType, IJsonModel} from 'models/JsonModel'

const models: {[key: string]: JsonModelInstanceType} = {
  Activity,
  Device,
  Usage,
  Patient,
  User,
}

const transform = createTransform(null, state => {
  traverse(state).forEach(function(val: IJsonModel) {
    if (val && val._modelType) {
      const Type = models[val._modelType]
      if (Type) {
        const inst = new Type(val._modelData)
        this.update(inst)
      }
    }
  })
  return state
})

export default transform
