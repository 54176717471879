import React, {useState} from 'react'
import {Box, Button, Container, TextField, Typography} from '@mui/material'
import styles from './styles'
import _ from 'lodash'
import {useRouteMatch} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {actions as userActions} from '../../redux/modules/users'
import {RootState} from 'redux/rootReducer'

const ResetPasswordPage: React.FC = () => {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [formError, setFormError] = useState<string | undefined>()
  const params = useRouteMatch<{userId: string; resetPasswordToken: string}>()
  const {userId, resetPasswordToken} = params.params
  let errorMessage = useSelector(({users}: RootState) => users.error)
  const dispatch = useDispatch()

  const resetPassword = () => {
    if (_.isEmpty(password) || _.isEmpty(passwordConfirm)) {
      setFormError('All fields are required.')
      return
    }
    if (password === passwordConfirm) {
      dispatch(userActions.resetPassword({userId, resetPasswordToken, password}))
      if (!errorMessage) {
        setPassword('')
        setPasswordConfirm('')
        setFormError('')
      }
      errorMessage = undefined
    } else {
      setFormError('Passwords do not match.')
      errorMessage = undefined
    }
  }

  return (
    <Container component="main" sx={styles.root}>
      <Box sx={styles.form} textAlign="center">
        <Typography sx={styles.headerLabel} variant="h5">
          Please choose a new password
        </Typography>
        <Typography sx={styles.formError} variant="body1" color="red">
          {formError || errorMessage}
        </Typography>
        <TextField
          sx={styles.textField}
          onChange={e => setPassword(e.target.value)}
          placeholder="Password"
          type="password"
          onKeyDown={args => args.key === 'Enter' && resetPassword()}
        />
        <TextField
          sx={styles.textField}
          onChange={e => setPasswordConfirm(e.target.value)}
          placeholder="Confirm password"
          type="password"
          onKeyDown={args => args.key === 'Enter' && resetPassword()}
        />
        <Button variant="contained" sx={styles.submitButton} onClick={resetPassword}>
          Submit
        </Button>
      </Box>
    </Container>
  )
}
export default ResetPasswordPage
