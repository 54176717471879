import JsonModel from './JsonModel'
import {ZonedDateTime} from '@js-joda/core'
import Patient, {PatientData} from './Patient'

type PainData = {
  id?: string
  level: number
  createdAt: string
  activityTypes: string
  journal: string
  patient?: PatientData
}

class Pain extends JsonModel {
  id?: string
  date: ZonedDateTime
  level: number
  activityTypes: string
  journal: string
  patient?: Patient

  constructor(args: PainData) {
    super('Pain', args)
    this.id = args.id
    this.date = ZonedDateTime.parse(args.createdAt)
    this.level = args.level
    this.activityTypes = args.activityTypes
    this.journal = args.journal
    if (args.patient) {
      this.patient = new Patient(args.patient)
    }
  }

  /**
   * Returns the data inluded when exporting a csv
   */
  get exportData() {
    const {id, level, createdAt, activityTypes, journal} = this._modelData
    return {
      id,
      level,
      createdAt,
      activityTypes: `[${activityTypes.join(',')}]`,
      journal,
      patientId: this.patient?.id,
    }
  }
}

export default Pain
