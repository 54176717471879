import JsonModel from './JsonModel'
import {LocalDate} from '@js-joda/core'
import {UserData} from './User'

export type PatientData = {
  id?: string
  firstName: string
  lastName: string
  physicianName: string
  birthdate: string
  user?: UserData
}

class Patient extends JsonModel {
  id: string
  firstName: string
  lastName: string
  physicianName: string
  birthdate: LocalDate
  email?: string

  constructor(args: PatientData) {
    super('Patient', args)
    this.id = args.id || ''
    this.firstName = args.firstName
    this.lastName = args.lastName
    this.physicianName = args.physicianName
    this.birthdate = LocalDate.parse(args.birthdate)
    this.email = args.user ? args.user.email : undefined
  }

  fullName() {
    return `${this.firstName} ${this.lastName}`
  }
}

export default Patient
