import React from 'react'
import {GridColDef} from '@mui/x-data-grid'
import {Button} from '@mui/material'

export const createUserColumns = (onClickResetPassword: (userId: string) => void) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email address',
      flex: 1,
    },
    {
      field: 'Admin',
      headerName: 'Admin',
      valueGetter: params => (params.row.admin ? 'Yes' : 'No'),
      flex: 1,
    },
    {
      field: 'actions',
      headerName: '',
      hideable: false,
      disableColumnMenu: true,
      type: 'actions',
      width: 180,
      renderCell: params => (
        <>
          <Button variant="contained" onClick={() => onClickResetPassword(params.id.toString())}>
            Reset password
          </Button>
        </>
      ),
    },
  ]
  return columns
}
