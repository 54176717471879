import React from 'react'
import {GridColDef} from '@mui/x-data-grid'
import {Button} from '@mui/material'
import Device from 'models/Device'

export const createDeviceColumns = (
  onClickEdit: (device: Device) => void,
  onClickDelete: (deviceId: string) => void,
) => {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'udid',
      headerName: 'UDID',
      flex: 1,
    },
    {
      field: 'patient',
      headerName: 'Patient Id',
      valueGetter: params => params.row.patient?.id,
      flex: 1,
    },

    {
      field: 'actions',
      headerName: '',
      hideable: false,
      disableColumnMenu: true,
      type: 'actions',
      width: 200,
      renderCell: params => (
        <>
          <Button variant="contained" onClick={() => onClickEdit(params.row)}>
            Edit
          </Button>
          <Button
            sx={{marginLeft: 2}}
            onClick={() => onClickDelete(params.id.toString())}
            variant="contained"
            color="error">
            Delete
          </Button>
        </>
      ),
    },
  ]
  return columns
}
