import JsonModel from './JsonModel'
import Patient, {PatientData} from './Patient'

export type DeviceData = {
  id?: string
  udid: string
  name: string
  createdAt: Date
  patient?: PatientData
}

class Device extends JsonModel {
  id: string
  udid: string
  name: string
  createdAt: Date
  patient?: Patient

  constructor(args: DeviceData) {
    super('Device', args)
    this.id = args.id || ''
    this.name = args.name
    this.udid = args.udid
    this.createdAt = args.createdAt
    if (args.patient) {
      this.patient = new Patient(args.patient)
    }
  }
}

export default Device
