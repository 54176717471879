import {createAction, createReducer} from '@reduxjs/toolkit'
import {takeEvery, getContext, put, call} from 'redux-saga/effects'
import {keyBy} from 'lodash'
import Usage from '../../models/Usage'
import Api from 'Api'

export type UsageState = {
  [key: string]: Usage
}
export const initialState: UsageState = {}

export const actions = {
  set: createAction<{usage: Usage[]}>('usage/set'),
  get: createAction<{deviceId: string}>('usage/get'),
  getAll: createAction<void>('usage/getAll'),
}

export const reducer = createReducer(initialState, {
  [actions.set.type]: (state, action: ReturnType<typeof actions.set>) => {
    const {usage} = action.payload
    return keyBy(usage, 'id')
  },
})

const getAllSaga = function*() {
  //todo: discuss this
}

const getSaga = function*(action: ReturnType<typeof actions.get>) {
  const {deviceId} = action.payload
  const api: Api = yield getContext('api')
  const usage: Usage[] = yield call(api.getUsage, deviceId)
  yield put(actions.set({usage}))
}

export const saga = function*() {
  yield takeEvery(actions.get, getSaga)
  yield takeEvery(actions.getAll, getAllSaga)
}
