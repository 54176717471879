import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import Api from 'Api'
import Device from '../../models/Device'
import Patient from '../../models/Patient'
import User from '../../models/User'
import {call, getContext, put, select, takeEvery} from 'redux-saga/effects'
import {RootState} from 'redux/rootReducer'

export type SearchResult = {
  users: User[]
  patients: Patient[]
  devices: Device[]
}

type SearchState = {
  results: SearchResult
  loading: boolean
  error?: string
  query?: string
}

const initialState: SearchState = {
  results: {
    users: [],
    patients: [],
    devices: [],
  },
  loading: false,
}

export const {actions, reducer} = createSlice({
  name: 'search',
  initialState,
  reducers: {
    search: (state: SearchState, action: PayloadAction<{query: string}>) => {
      const {query} = action.payload
      state.query = query
    },
    refreshSearch: (_state: SearchState, _action: PayloadAction<void>) => {},
    setLoading: (state: SearchState, action: PayloadAction<{loading: boolean}>) => {
      const {loading} = action.payload
      state.loading = loading
    },
    setError: (state: SearchState, action: PayloadAction<{error?: string}>) => {
      const {error} = action.payload
      state.error = error
    },
    setResults: (state: SearchState, action: PayloadAction<{results: SearchResult}>) => {
      const {results} = action.payload
      state.results = results
    },
  },
})

const searchSaga = function*(action: ReturnType<typeof actions.search>) {
  const {query} = action.payload
  const api: Api = yield getContext('api')
  const results = yield call(api.search, query)
  yield put(actions.setResults({results}))
}

const refreshSearchSaga = function*() {
  const {query} = yield select(({search}: RootState) => search)
  yield put(actions.search({query}))
}

export const saga = function*() {
  yield takeEvery(actions.search, searchSaga)
  yield takeEvery(actions.refreshSearch, refreshSearchSaga)
}
