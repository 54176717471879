import {createAction, createReducer} from '@reduxjs/toolkit'
import {indexOf} from 'lodash'
import {takeEvery, setContext} from 'redux-saga/effects'
import Api from '../../Api'
import environments, {Environment} from '../../environments'

export type EnvironmentsState = {
  current: number
}
export const initialState: EnvironmentsState = {
  current: 0, // the current environment index
}

export const actions = {
  setCurrent: createAction<{environment: Environment}>('environments/setCurrent'),
}

export const reducer = createReducer(initialState, {
  [actions.setCurrent.type]: (state, action: ReturnType<typeof actions.setCurrent>) => {
    const {environment} = action.payload
    const current = indexOf(environments, environment)
    return {...state, current}
  },
})

const setCurrentSaga = function*(action: ReturnType<typeof actions.setCurrent>) {
  const {environment} = action.payload
  const api = new Api(environment)
  yield setContext({api})
}

export const saga = function*() {
  yield takeEvery(actions.setCurrent, setCurrentSaga)
}
