import {createAction, createReducer} from '@reduxjs/toolkit'
import {takeEvery, getContext, put, call} from 'redux-saga/effects'
import {keyBy} from 'lodash'
import Activity from '../../models/Activity'
import Api from 'Api'

export type ActivityState = {
  [key: string]: Activity
}
export const initialState: ActivityState = {}

export const actions = {
  set: createAction<{activity: Activity[]}>('activity/set'),
  getAll: createAction<void>('activity/getAll'),
  get: createAction<{deviceId: string}>('activity/get'),
}

export const reducer = createReducer(initialState, {
  [actions.set.type]: (state, action: ReturnType<typeof actions.set>) => {
    const {activity} = action.payload
    return keyBy(activity, 'id')
  },
})

const getAllSaga = function*() {
  // todo: discuss this
}

const getSaga = function*(action: ReturnType<typeof actions.get>) {
  const {deviceId} = action.payload
  const api: Api = yield getContext('api')
  const activity: Activity[] = yield call(api.getActivity, deviceId)
  yield put(actions.set({activity}))
}

export const saga = function*() {
  yield takeEvery(actions.getAll, getAllSaga)
  yield takeEvery(actions.get, getSaga)
}
