import React, {useState} from 'react'
import {Button, CircularProgress, Container, TextField, Typography} from '@mui/material'
import {Box} from '@mui/system'
import {ModalPage} from 'nav/ModalPage'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import {RootState} from 'redux/rootReducer'
import {LocalDate} from '@js-joda/core'
import {actions as patientActions} from '../../redux/modules/patients'
import Patient from 'models/Patient'
import styles from './styles'

const CreatePatientPage: ModalPage<{patient?: Patient}> = props => {
  const dispatch = useDispatch()
  const loading = useSelector(({patients}: RootState) => patients.loading)
  const patientError = useSelector(({patients}: RootState) => patients.error)
  const {onClickCancel, patient} = props
  const [shouldClose, setShouldClose] = useState(false)
  const [firstName, setFirstName] = useState(patient?.firstName ?? '')
  const [lastName, setLastName] = useState(patient?.lastName ?? '')
  const [physicianName, setPhysicianName] = useState(patient?.physicianName ?? '')
  const [birthdate, setBirthdate] = useState(patient?.birthdate.toString() ?? '')
  const [formError, setFormError] = useState<string | undefined>()
  const [birthdateError, setBirthdateError] = useState<string | undefined>()
  const [email, setEmail] = useState<string | undefined>(patient?.email)

  if (loading && !shouldClose) {
    setShouldClose(true)
  }
  if (shouldClose && !patientError && !loading) {
    onClickCancel()
  }

  const closePage = () => {
    dispatch(patientActions.setError({error: undefined}))
    onClickCancel()
  }

  const savePatient = () => {
    setFormError(undefined)
    setBirthdateError(undefined)

    if (
      _.isEmpty(firstName) ||
      _.isEmpty(lastName) ||
      _.isEmpty(physicianName) ||
      _.isEmpty(birthdate)
    ) {
      setFormError('All fields are required.')
      return
    }

    try {
      LocalDate.parse(birthdate)
    } catch (e) {
      setBirthdateError('Invalid birth date.')
      return
    }
    if (patient) {
      patient.firstName = firstName
      patient.lastName = lastName
      patient.physicianName = physicianName
      patient.birthdate = LocalDate.parse(birthdate)
      patient.email = email
      dispatch(patientActions.update({patient}))
    } else {
      const newPatient = new Patient({firstName, lastName, physicianName, birthdate})
      dispatch(patientActions.add({patient: newPatient}))
    }
  }

  return (
    <Container>
      <Box sx={styles.modal}>
        <Typography sx={styles.headerLabel} variant="h4">
          Add patient
        </Typography>
        <Typography sx={styles.formError} variant="body1" color="red">
          {formError ?? patientError}
        </Typography>

        <TextField
          sx={styles.textField}
          fullWidth
          defaultValue={firstName}
          onChange={e => setFirstName(e.currentTarget.value)}
          placeholder="First name"
        />
        <TextField
          sx={styles.textField}
          fullWidth
          defaultValue={lastName}
          onChange={e => setLastName(e.currentTarget.value)}
          placeholder="Last name"
        />
        <TextField
          sx={styles.textField}
          fullWidth
          defaultValue={physicianName}
          onChange={e => setPhysicianName(e.currentTarget.value)}
          placeholder="Physician name"
        />
        <TextField
          error={!!birthdateError}
          helperText={birthdateError}
          sx={styles.textField}
          fullWidth
          defaultValue={birthdate}
          onChange={e => setBirthdate(e.currentTarget.value)}
          placeholder="Birth date (yyyy-mm-dd)"
        />
        {email && (
          <TextField
            sx={styles.textField}
            fullWidth
            defaultValue={email}
            onChange={e => setEmail(e.currentTarget.value)}
            placeholder="Email"
          />
        )}
        <Box sx={styles.buttonContainer}>
          <Button sx={styles.cancelButton} color="warning" onClick={closePage}>
            Cancel
          </Button>
          {loading && (
            <Box sx={styles.progress}>
              <CircularProgress size={20} />
            </Box>
          )}
          {!loading && (
            <Button onClick={savePatient} variant="contained">
              Save
            </Button>
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default CreatePatientPage
