/**
 * Creates a download element that triggers a file download, and then triggers it.
 * @param content the content of the file
 * @param filename the file name and extension
 */
export default async (content: string, filename: string) => {
  let blob = new window.Blob([content])
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}
